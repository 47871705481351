<template>
  <v-select
    multiple
    v-model="selectedOption"
    class="invision-multiple-select"
    :name="name"
    :options="selectOptions"
    @input="valueChanged"
    :disabled="isBusy || disabled"
  >
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        Nenhum resultado encontrado para <em>'{{ search }}'</em>
      </template>
      <em style="opacity: 0.5" v-else>Nenhuma opção disponível</em>
    </template>
  </v-select>
</template>

<script>

import GenericApi from '@/services/genericRequest';

export default {
  name: 'GenericMultipleSelect',
  props: {
    name: {
      type: String,
      required: false,
    },
    // Default value of the input, also used for edition
    value: {
      type: Array,
      default: null,
    },
    // Indicates if the field is disabled
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
    },
    // Method used to retrieve the available oprions
    apiFunction: {
      type: String,
      required: false,
      default() {
        return 'getWithoutPagination';
      },
    },
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
    route: {
      type: String,
      required: true,
    },
    // Filters used on the api method
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    // Filters used on the api method
    customFilters: {
      type: Object,
      default() {
        return {};
      },
    },
    // Name of the attribute that contains the displayed label
    labelKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isBusy: false,
      options: [],
      selectedOption: this.value,
    };
  },

  computed: {
    selectOptions() {
      const modelIds = this.selectedOption.map((slection) => slection.value);
      return this.options.filter((option) => !modelIds.includes(option.value));
    },
  },

  mounted() {
    this.fillOptions();
  },

  watch: {
    customFilters() {
      this.fillOptions();
      this.selectedOption = null;
      this.valueChanged();
    },
    value() {
      this.selectedOption = this.value;
    },
  },

  methods: {
    valueChanged() {
      if (!this.disabled) {
        this.$emit('input', this.selectedOption);
      }
    },

    async fillOptions() {
      this.options = [];
      this.isBusy = true;
      const { customFilters } = this;
      const result = await this.api[this.apiFunction](
        { customFilters },
        this.route,
      );

      const idKey = this.getIdKeyFromObject(result[0]);

      this.options = result.map((row) => ({
        label: row[this.labelKey],
        value: row[idKey],
      }));

      this.selectedOption = this.value;

      this.isBusy = false;
    },

    getIdKeyFromObject(object) {
      return Object.keys(object).find((key) => key.slice(0, 2) === 'id');
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  height: 40px;
  border-radius: 7px;
  height: fit-content ;
}
</style>
