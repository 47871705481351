<template>
  <div>
    <GenericTable
      ref="lista"
      name="Fluxos"
      title="fluxos"
      noadd
      :nofilters="true"
      :cols="['Id', 'Editar', 'descricao']"
      :cols-name="['id_fluxo', 'editar', 'descricao']"
      :cols-map="i => [i.id_fluxo, i, i.descricao]"
      :route="route"
      :permissionsToWrite="['rw_fluxo']"
    >
      <template #Editar="{ item }">
        <b-btn
          data-cy="editar"
          class="btn btn-sm btn-outline-light text--black"
          title="Editar"
          @click="onClickEdicaoFluxo(item)"
        >
          <edit-icon class="w20px" />
        </b-btn>
      </template>
    </GenericTable>

    <Modal
      title=""
      ref="modalEdicaoFluxo"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      centered
    >
      <EdicaoFluxo
        @closeModal="closeModal"
        @updateModel="updateModel"
        :model="fluxoAtivo"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Utils/Modal';
import EdicaoFluxo from './Edit';

export default {
  components: {
    Modal,
    EdicaoFluxo,
  },
  data() {
    return {
      route: 'fluxo',
      fluxoAtivo: {},
    };
  },
  methods: {
    onClickEdicaoFluxo(fluxo) {
      [, this.fluxoAtivo] = fluxo.cols;
      this.$refs.modalEdicaoFluxo.show();
    },

    closeModal() {
      this.$refs.modalEdicaoFluxo.hide();
    },

    updateModel(model) {
      this.fluxoAtivo = model;
    },
  },
};
</script>
