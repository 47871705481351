<template>
  <div>
    <b-table
      :fields="fields"
      :items="model"
      empty-text="Não existe ações para este processo"
    >
      <template #cell(acao)="{ item }">
        <GenericSelect
          key="id_acao"
          ref='acao'
          :route="routeAcoes"
          labelKey='descricao'
          v-model="item.id_acao"
        />
      </template>

      <template #cell(tipo)="{ item }">
        <GenericSelect
          ref='tipo'
          :route="routeTipos"
          labelKey='label'
          v-model="item.tipo"
        />
      </template>

      <template #cell(validacoes)="{ item }">
        <GenericMultipleSelect
          labelKey="chave"
          :route="routeValidacaoAcao"
          :value="item.validacoes.map((el) => ({ value: el.id_validacao_acao, label: el.descricao }))"
          @input="state=>changeValidacoes(item, state)"
        ></GenericMultipleSelect>
      </template>

      <template #cell(terminoAutomatico)="{ item }">
        <b-checkbox
          v-model="item.termino_automatico"
        />
      </template>

      <template #cell(excluir)="{ index }">
        <b-button
          class="btn btn-sm text--black"
          variant="outline-dark"
          @click="removeAcao(index)"
        >
          <trash-2-icon class="w20px" />
        </b-button>
      </template>
    </b-table>
    <Button
      text="Cancelar"
      @click="cancel"
      variant="grayscale"
    />
    <Button
      text="Salvar"
      @click="save"
    />
    <Button
      text="Adicionar"
      @click="add"
    />
  </div>
</template>

<script>

import genericRequest from '@/services/genericRequest';
import GenericSelect from '@/components/Form/GenericSelect';
import GenericMultipleSelect from '@/components/Form/GenericMultipleSelect';
import Button from '@/components/Utils/Button';

export default {
  props: {
    processoTipoMaterial: {
      type: Object,
      required: true,
    },
  },

  components: {
    GenericSelect,
    GenericMultipleSelect,
    Button,
  },

  mounted() {
    this.model = [
      ...this.processoTipoMaterial.acoes,
    ];
  },

  data() {
    return {
      fields: [{
        key: 'acao',
        label: 'Ação',
      }, {
        key: 'tipo',
        label: 'Tipo',
      }, {
        key: 'validacoes',
        label: 'Validações',
      }, {
        key: 'terminoAutomatico',
        label: 'Término Automático',
      }, {
        key: 'excluir',
        label: 'Excluir',
      }],
      routeAcoes: 'acao',
      routeTipos: 'tipoAcao',
      routeValidacaoAcao: 'validacaoAcao',
      model: [],
    };
  },

  methods: {
    async save() {
      try {
        await genericRequest
          .create(
            {
              acoes: this.model,
              id_processo_tipo_material: this.processoTipoMaterial.id_processo_tipo_material,
            }, 'fluxo/processoTipoMaterialAcao',
          );

        await swal({
          title: 'Sucesso',
          text: 'Sucesso ao atualizar as ações',
          icon: 'success',
          button: 'Continuar...',
        });

        this.$emit('closeAcoesModal', this.model);
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao atualizar as acoes';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
    },

    removeAcao(index) {
      this.model = [...this.model.slice(0, index), ...this.model.slice(index + 1, this.model.length)];
    },

    add() {
      this.model.push({
        tipo: null,
        validacoes: [],
        termino_automatico: false,
      });
    },

    cancel() {
      this.model = [];
      this.$emit('closeAcoesModal');
    },

    async changeValidacoes(item, value) {
      item.validacoes = value.map((v) => ({
        id_validacao_acao: v.value,
        descricao: v.label,
      }));
    },
  },
};

</script>

<style>

</style>
