<template>
  <div>
    <GenericForm
      title="Fluxo"
      :model="model"
      route="fluxo"
      :editing="true"
      :exclusionEnabled="false"
      onlyVisualize
      @updateModel="updateModel"
      @canceled="closeModal"
      @deleted="closeModal"
      idModel="id_fluxo"
      :permissionsToWrite="['rw_fluxo']"
      :permissionsToEdit="['rw_fluxo']"
      :permissionsToDelete="['rw_fluxo']"
    >
      <template v-slot="{ isBusy }">
        <div v-if="isBusy || parsingSubsetores" class="spinner-container">
          <b-spinner small variant="info" class="large-spinner"/>
        </div>
        <div v-else>
          <b-table
            :fields="fieldsSubSetores"
            :items="subSetores"
            class="mt-3"
            responsive
            show-empty
            empty-text="Não existem informações para serem exibidos"
          >
            <template #cell(opcional)="{ item }">
              <b-checkbox
                :checked="item.opcional"
                :disabled="item.busy"
                v-if="!item.busy"
                @input="toggleSubSetorOpcional(item)"
              />
              <b-spinner v-if="item.busy" small variant="info" class="mini-spinner" />
            </template>
            <template #cell(depende_de_ordem)="{ item }">
              <b-input
                type="number"
                :value="item.depende_de_ordem > 0 ? item.depende_de_ordem : null"
                :disabled="item.busy"
                v-if="!item.busy"
                @input="state=>changeDependenciaSubSetor(item, state)"
              />
              <b-spinner v-if="item.busy" small variant="info" class="mini-spinner" />
            </template>
            <template #cell(processos)="{ item }">
              <GenericMultipleSelect
                labelKey="nome"
                route="tipoProcesso"
                :value="item.processos.map((p) => ({ value: p.id_tipo_processo, label: p.nome_tipo_processo }))"
                :disabled="item.busy"
                v-if="!item.busy"
                @input="state=>changeProcessosSubSetor(item, state)"
              ></GenericMultipleSelect>
              <b-spinner v-if="item.busy" small variant="info" class="mini-spinner" />
            </template>
            <!--
            <template #custom-foot="{ columns }">
              <b-tr>
                <b-th :colspan="parseInt(columns)">
                  <b-button
                    variant="none"
                    class="btn align-items-center secondary-contained-button text-white float-right"
                    :data-cy="`Adicionar etapa`"
                  >
                    <PlusIcon width="20" height="20" class=" with-icon" /> Adicionar etapa
                  </b-button>
                </b-th>
              </b-tr>
            </template>
            -->
          </b-table>
        </div>
          <br/>
          <hr/>

        <div v-if="isBusy || parsingTipoMaterialFluxos" class="spinner-container">
          <b-spinner small variant="info" class="large-spinner" />
        </div>
        <div v-else-if="processosSubSetorAlterado">
          <b-button
            variant="none"
            class="btn align-items-center secondary-contained-button text-white float-right w-100"
            @click="closeModal"
          >
            <span>
              <RefreshCwIcon width="20" height="20" class=" with-icon" />
              Processos alterados, favor fechar o modal.
            </span>
          </b-button>
        </div>
        <div v-else>
          <h3>Informações por tipo de material:</h3>
          <div
            v-for="tipoMaterialFluxo in fluxosByTipoMaterial"
            :key="`tipoMaterialFluxo-${tipoMaterialFluxo.id_tipo_material}`"
          >
            <b-card header-tag="header" footer-tag="footer">
              <template #header>
                <h5
                  class="mb-0 pt-3 pl-3 pr-3 title-header"
                  @click="toogleMaterialView(tipoMaterialFluxo.id_tipo_material)"
                >
                  <b>Tipo de Material:</b>
                  {{ tipoMaterialFluxo.nome_tipo_material }}
                  <chevron-up-icon
                    class="chevron-icon float-right"
                    :class="{ hidden: tipoMaterialFluxo.hidden }"
                  />
                </h5>
              </template>
              <b-collapse visible :id="`tipoMaterialFluxo-${tipoMaterialFluxo.id_tipo_material}`">
                <b-card-text>
                  <div
                    v-for="(subsetor, indexSubSetor) in tipoMaterialFluxo.subSetores"
                    :key="
                      `subsetor-${tipoMaterialFluxo.id_tipo_material}-${indexSubSetor}`
                    "
                  >
                    <b-row no-gutters>
                      <b-col md="3">
                        <div class="ordem-div">
                          <label class="ordem-label">
                            {{ `${subsetor.ordem}${subsetor.ordem === 1 ? 'º' : 'ª'}` }} ETAPA
                          </label>
                        </div>
                      </b-col>
                      <b-col md="9">
                        <b-card-body>
                          <br />
                          <b>Subsetor:</b> {{ subsetor.nome_sub_setor }}
                          <b-card-text>
                            <b-row>
                              <b-col md="12">
                                <b-table
                                  :fields="fieldsProcessos"
                                  :items="subsetor.processos"
                                  class="mt-3"
                                  responsive
                                  show-empty
                                  empty-text="Não existem processos para serem exibidos"
                                >
                                  <template #cell(nome_tipo_processo)= "{ item }">
                                  <b-form-select
                                    :options="tiposProcessoBySubSetor(subsetor.id_fluxo_sub_setor)"
                                    class="invision-input"
                                    :value="item.id_tipo_processo"
                                    v-if="!item.busy"
                                    @input="state=>changeTipoProcessoMaterial(item, subsetor, state)"
                                  >
                                    <template slot="first">
                                      <option disabled>-- Por favor, selecione uma opção --</option>
                                    </template>
                                  </b-form-select>
                                  <b-spinner v-if="item.busy" small variant="info" class="mini-spinner" />
                                  </template>
                                  <template #cell(id_proximo_fluxo)="{ item }">
                                    <b-input
                                      type="number"
                                      class="w-75"
                                      :value="item.id_proximo_fluxo > 0 ? item.id_proximo_fluxo : null"
                                      :disabled="item.busy"
                                      v-if="!item.busy"
                                      @input="state=>changeProximoFluxo(item, state)"
                                    />
                                    <b-spinner v-if="item.busy" small variant="info" class="mini-spinner" />
                                  </template>
                                  <template #cell(final)="{ item }">
                                    <b-checkbox
                                      :checked="item.final"
                                      :disabled="item.busy"
                                      v-if="!item.busy"
                                      @input="toggleProcessoFinal(item)"
                                    />
                                    <b-spinner v-if="item.busy" small variant="info" class="mini-spinner" />
                                  </template>
                                  <template #cell(acoes)="{ item }">
                                    <b>{{ item.acoes.length }}</b>
                                  </template>
                                  <template #cell(editar)="{ item }">
                                    <b-button
                                      class="btn btn-sm text--black"
                                      variant="outline-dark"
                                      v-if="!item.busy"
                                      @click="editAcao(item)"
                                    >
                                      <edit-icon class="w20px" />
                                    </b-button>
                                    <b-spinner v-if="item.busy" small variant="info" class="mini-spinner" />
                                  </template>
                                  <template #cell(excluir)="{ item }">
                                    <b-button
                                      class="btn btn-sm text--black"
                                      variant="outline-dark"
                                      v-if="!item.busy"
                                      @click="removeProcessoTipoMaterial(item, subsetor)"
                                    >
                                      <trash-2-icon class="w20px" />
                                    </b-button>
                                    <b-spinner v-if="item.busy" small variant="info" class="mini-spinner" />
                                  </template>
                                </b-table>
                                <br/>
                                <b-button
                                  v-if="subsetor.processos.length
                                    < tiposProcessoBySubSetor(subsetor.id_fluxo_sub_setor).length"
                                  variant="none"
                                  class="btn align-items-center secondary-contained-button text-white float-right w-100"
                                  :data-cy="`Adicionar processo`"
                                  @click="addNovoProcessoAoTipoMaterial(tipoMaterialFluxo.id_tipo_material, subsetor)"
                                >
                                  <span v-if="!criandoProcessoTipoMaterial">
                                    <PlusIcon width="20" height="20" class=" with-icon" /> Adicionar novo processo
                                  </span>
                                  <b-spinner v-else small variant="info" class="mini-spinner" />
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-card-text>
                        </b-card-body>
                      </b-col>
                    </b-row>
                    <hr v-if="indexSubSetor < tipoMaterialFluxo.subSetores.length - 1"/>
                  </div>
                </b-card-text>
              </b-collapse>
            </b-card>
            <br />
          </div>
        </div>
      </template>
    </GenericForm>

    <Modal
      title=""
      id="modalEdicaoAcao"
      ref="modalEdicaoAcao"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      centered
    >
      <EdicaoAcao
        :processoTipoMaterial="editingProcessoTipoMaterial"
        @closeAcoesModal="closeAcoesModal"
      />
    </Modal>
  </div>
</template>

<script>
import GenericForm from '@/components/Form/GenericForm';
import GenericMultipleSelect from '@/components/Form/GenericMultipleSelect';
import Modal from '@/components/Utils/Modal';
import EdicaoAcao from '../Acao/Edit';

import genericRequest from '@/services/genericRequest';

export default {
  components: {
    GenericForm,
    GenericMultipleSelect,
    Modal,
    EdicaoAcao,
  },

  methods: {
    editAcao(item) {
      this.editingProcessoTipoMaterial = item;
      this.$refs.modalEdicaoAcao.show();
    },

    closeAcoesModal(acoes) {
      this.editingProcessoTipoMaterial.acoes = [...acoes];
      this.$refs.modalEdicaoAcao.hide();
    },

    closeModal() {
      this.$emit('closeModal');
    },

    updateModel(model) {
      this.$emit('updateModel', model);
    },

    toogleMaterialView(id_tipo_material) {
      const tipoMaterialFluxo = this.fluxosByTipoMaterial.find((f) => f.id_tipo_material === id_tipo_material);
      tipoMaterialFluxo.hidden = !tipoMaterialFluxo.hidden;

      this.$root.$emit('bv::toggle::collapse', `tipoMaterialFluxo-${id_tipo_material}`);
    },

    parseTipoMaterialFluxos() {
      this.parsingTipoMaterialFluxos = true;
      if (!this.model.subSetores) {
        setTimeout(this.parseTipoMaterialFluxos.bind(this), 100);
        return;
      }

      const maximumOrdem = this.model.subSetores
        .reduce((prev, current) => ((prev.ordem > current.ordem) ? prev : current)).ordem;

      let tiposMaterial = this.model.subSetores.reduce((result, subSetor) => {
        subSetor.processos.forEach((processo) => {
          processo.tiposMaterial.forEach((tipoMaterial) => {
            if (!result[tipoMaterial.id_tipo_material]) {
              result[tipoMaterial.id_tipo_material] = {
                id_tipo_material: tipoMaterial.id_tipo_material,
                nome_tipo_material: tipoMaterial.tipoMaterial.nome,
                hidden: false,
                subSetores: [],
              };

              for (let i = 1; i <= maximumOrdem; i++) {
                result[tipoMaterial.id_tipo_material].subSetores[i] = {
                  id_fluxo_sub_setor: this.model.subSetores.find((ss) => ss.ordem === i).id_fluxo_sub_setor,
                  depende_de_ordem: this.model.subSetores.find((ss) => ss.ordem === i).depende_de_ordem,
                  id_sub_setor: this.model.subSetores.find((ss) => ss.ordem === i).id_sub_setor,
                  nome_sub_setor: this.model.subSetores.find((ss) => ss.ordem === i).subSetor.nome,
                  opcional: this.model.subSetores.find((ss) => ss.ordem === i).opcional,
                  ordem: this.model.subSetores.find((ss) => ss.ordem === i).ordem,
                  processos: [],
                };
              }
            }

            result[tipoMaterial.id_tipo_material].subSetores[subSetor.ordem].processos.push({
              id_tipo_processo: processo.id_tipo_processo,
              id_processo: processo.id_processo,
              nome_tipo_processo: processo.tipoProcesso.nome,
              final: tipoMaterial.final,
              id_proximo_fluxo: tipoMaterial.id_proximo_fluxo,
              id_processo_tipo_material: tipoMaterial.id_processo_tipo_material,
              busy: false,
              acoes: tipoMaterial.acoes,
            });
          });
        });

        return result;
      }, {});

      tiposMaterial = Object.values(tiposMaterial);
      tiposMaterial.forEach((tp) => tp.subSetores.sort((a, b) => a.ordem - b.ordem));
      this.fluxosByTipoMaterial = tiposMaterial.map((tp) => ({
        ...tp,
        subSetores: tp.subSetores.filter((ss) => ss),
      }));

      this.parsingTipoMaterialFluxos = false;
    },

    parseSubSetores() {
      this.parsingSubsetores = true;
      if (!this.model.subSetores) {
        setTimeout(this.parseSubSetores.bind(this), 100);
        return;
      }

      this.subSetores = this.model.subSetores.map((ss) => ({
        nome_sub_setor: ss.subSetor.nome,
        depende_de_ordem: ss.depende_de_ordem,
        id_fluxo_sub_setor: ss.id_fluxo_sub_setor,
        id_sub_setor: ss.id_sub_setor,
        opcional: ss.opcional,
        ordem: ss.ordem,
        processos: ss.processos.map((p) => ({
          id_tipo_processo: p.id_tipo_processo,
          nome_tipo_processo: p.tipoProcesso.nome,
        })),
      })).sort((a, b) => a.ordem - b.ordem);

      this.parsingSubsetores = false;
    },

    async toggleSubSetorOpcional(subsetor) {
      this.$set(subsetor, 'busy', true);
      try {
        await genericRequest
          .update(subsetor.id_fluxo_sub_setor, { opcional: !subsetor.opcional }, 'fluxo/fluxoSubSetor');

        subsetor.opcional = !subsetor.opcional;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao atualizar o subsetor';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
      this.$set(subsetor, 'busy', false);
    },

    tiposProcessoBySubSetor(id_fluxo_sub_setor) {
      const subSetor = this.subSetores.find((ss) => ss.id_fluxo_sub_setor === id_fluxo_sub_setor);
      if (!subSetor) return [];

      return subSetor.processos.map((p) => ({ value: p.id_tipo_processo, text: p.nome_tipo_processo }));
    },

    async changeProcessosSubSetor(subsetor, value) {
      this.processosSubSetorAlterado = true;

      const { id_fluxo_sub_setor } = subsetor;
      const ids_tipo_processo = value.map((v) => v.value);

      this.$set(subsetor, 'busy', true);
      try {
        await genericRequest
          .create({ id_fluxo_sub_setor, ids_tipo_processo }, 'fluxo/processo');

        subsetor.processos = value.map((v) => ({
          id_tipo_processo: v.value,
          nome_tipo_processo: v.label,
        }));
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao atualizar o subsetor';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
      this.$set(subsetor, 'busy', false);
    },

    async changeDependenciaSubSetor(subsetor, value) {
      this.$set(subsetor, 'busy', true);
      let depende_de_ordem;
      try {
        if (parseInt(value, 10) <= 0) depende_de_ordem = null;
        else depende_de_ordem = parseInt(value, 10);

        await genericRequest
          .update(
            subsetor.id_fluxo_sub_setor,
            { depende_de_ordem },
            'fluxo/fluxoSubSetor',
          );

        subsetor.depende_de_ordem = depende_de_ordem;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao atualizar o processo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
      this.$set(subsetor, 'busy', false);
    },

    async toggleProcessoFinal(processo) {
      this.$set(processo, 'busy', true);
      try {
        await genericRequest
          .update(processo.id_processo_tipo_material, { final: !processo.final }, 'fluxo/processoTipoMaterial');

        processo.final = !processo.final;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao atualizar o processo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
      this.$set(processo, 'busy', false);
    },

    async changeProximoFluxo(processo, value) {
      this.$set(processo, 'busy', true);
      let id_proximo_fluxo;
      try {
        if (parseInt(value, 10) <= 0) id_proximo_fluxo = null;
        else id_proximo_fluxo = parseInt(value, 10);

        await genericRequest
          .update(
            processo.id_processo_tipo_material,
            { id_proximo_fluxo },
            'fluxo/processoTipoMaterial',
          );

        processo.id_proximo_fluxo = id_proximo_fluxo;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao atualizar o processo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
      this.$set(processo, 'busy', false);
    },

    async changeTipoProcessoMaterial(processo, subsetor, id_tipo_processo) {
      this.$set(processo, 'busy', true);

      try {
        const tipoProcessoJaExiste = subsetor.processos
          .some((p) => p.id_processo !== processo.id_processo && p.id_tipo_processo === id_tipo_processo);

        if (tipoProcessoJaExiste) {
          await swal({
            title: 'Erro',
            text: 'Esse tipo de processo já está cadastrado para essa etapa e tipo de material',
            icon: 'error',
            button: 'Continuar...',
          });
          return;
        }

        await genericRequest
          .update(
            processo.id_processo_tipo_material,
            { id_tipo_processo, id_fluxo_sub_setor: subsetor.id_fluxo_sub_setor },
            'fluxo/processoTipoMaterial',
          );

        processo.id_tipo_processo = id_tipo_processo;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao atualizar o processo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      } finally {
        this.$set(processo, 'busy', false);
      }
    },

    async addNovoProcessoAoTipoMaterial(id_tipo_material, subsetor) {
      this.criandoProcessoTipoMaterial = true;
      const processosSubsetor = this.subSetores
        .find((ss) => ss.id_fluxo_sub_setor === subsetor.id_fluxo_sub_setor).processos;

      const processoLivre = processosSubsetor
        .find((ps) => !subsetor.processos.some((ss) => ss.id_tipo_processo === ps.id_tipo_processo));

      try {
        const result = await genericRequest
          .create({
            id_tipo_material,
            id_tipo_processo: processoLivre.id_tipo_processo,
            id_fluxo_sub_setor: subsetor.id_fluxo_sub_setor,
          }, 'fluxo/processoTipoMaterial');

        subsetor.processos.push({
          busy: false,
          final: false,
          id_processo: result.id_processo,
          id_processo_tipo_material: result.id_processo_tipo_material,
          id_proximo_fluxo: result.id_proximo_fluxo,
          id_tipo_processo: processoLivre.id_tipo_processo,
          nome_tipo_processo: processoLivre.nome_tipo_processo,
        });
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao criar o processo tipo material';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
      this.criandoProcessoTipoMaterial = false;
    },

    async removeProcessoTipoMaterial(processo, subsetor) {
      const deveContinuar = await swal({
        title: 'Exclusão de processo',
        text: 'Deseja exlcuir o processo?',
        icon: 'error',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });
      if (!deveContinuar) return;

      this.$set(processo, 'busy', true);
      try {
        await genericRequest
          .delete(processo.id_processo_tipo_material, 'fluxo/processoTipoMaterial');

        subsetor.processos = subsetor.processos
          .filter((p) => p.id_processo_tipo_material !== processo.id_processo_tipo_material);
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao excluir o processo tipo material';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
      this.$set(processo, 'busy', false);
    },
  },

  created() {
    this.parseTipoMaterialFluxos();
    this.parseSubSetores();
  },

  props: {
    model: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      processosSubSetorAlterado: false,
      parsingSubsetores: false,
      subSetores: [],
      fieldsSubSetores: [{
        key: 'nome_sub_setor',
        label: 'Subsetor',
      }, {
        key: 'ordem',
        label: 'Ordem/Etapa',
      }, {
        key: 'opcional',
        label: 'Opcional',
      }, {
        key: 'depende_de_ordem',
        label: 'Depende de',
      }, {
        key: 'processos',
        label: 'Processos',
      }],

      parsingTipoMaterialFluxos: false,
      criandoProcessoTipoMaterial: false,
      fluxosByTipoMaterial: [],
      fieldsProcessos: [{
        key: 'nome_tipo_processo',
        label: 'Processo',
      }, {
        key: 'id_proximo_fluxo',
        label: 'Próximo Fluxo',
      }, {
        key: 'final',
        label: 'Final',
      }, {
        key: 'acoes',
        label: 'Qtd. ações',
      }, {
        key: 'editar',
        label: 'Editar',
      }, {
        key: 'excluir',
        label: 'Excluir',
      }],
      editingAcoes: [],
      editingProcessoTipoMaterial: [],
    };
  },
};
</script>

<style scoped>
.ordem-div {
  border-style: solid;
  border-radius: 100%;
  border-color: #ff7d29;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(50% - 30px - 4.5rem), -50%);
  height: 6.5rem;
  width: 6.5rem;
  border-width: 6px;
}
.ordem-label {
  text-align: center;
  position: absolute;
  font-size: 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-header {
  background-color: #ff7d29;
}

.chevron-icon {
  transition: 0.5s;
}
.chevron-icon.hidden {
  transform: rotate(180deg);
}

.title-header {
  cursor: pointer;
}

.spinner-container {
  text-align: center;
}
.large-spinner {
  width: 4rem;
  height: 4rem;
}
</style>
